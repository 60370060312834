import React, { FC } from 'react';
import { JobDetailsProps } from '../../../types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Translate } from '../../translations';
import './styles.scss';

const JobDetailsTable: FC<JobDetailsProps> = ({ vacancy }) => (
  <div className='inner-row'>
    <div className="vacancy_image col-12 col-sm-5 col-lg-12">{vacancy.image}</div>
    <div className="job_info col-12 col-sm-5 col-lg-12">
      <div className="info_head">
        <h6 className="f_p f_600 f_size_18 t_color3">
          <Translate name="CAREER_JOB_DETAILS_TABLE_TITLE" />
        </h6>
      </div>
      {vacancy.tableData.map(data => {
        return (
          <div key={data.key} className="info_item d-flex">
            <FontAwesomeIcon icon={data.icon} className="icon" />
            <div>
              <h6>{data.title}</h6>
              <p>{data.description}</p>
            </div>
          </div>
        );
      })}
    </div>
  </div>
);

export default JobDetailsTable;
