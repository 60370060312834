import React, { FC } from 'react';
import { JobDetailsProps } from '../../../types';
import './styles.scss';
import { Translate } from '../../../components/translations';

const JobDescription: FC<JobDetailsProps> = ({ vacancy }) => (
  <div className="details_content col-lg-8">
    <h3>
      <Translate name="CAREER_JOB_DESCRIPTION_TITLE" />
    </h3>
    <p>{vacancy.description}</p>
    <h3>
      <Translate name="CAREER_JOB_RESPONSIBILITIES_TITLE" />
    </h3>
    {vacancy.responsibilities}
    <h3>
      <Translate name="CAREER_JOB_QUALIFICATIONS_TITLE" />
    </h3>
    {vacancy.requiredQualifications}
    <div className="jobsearch-job-userlist row mt_60">
      {vacancy.title.props.name === 'CAREER_YOUTUBER_TITLE' ? (
        <>
          <div className="col-12 col-md-4 deadline">
            Application Deadline: {vacancy.deadline}
          </div>
          <div className="col-12 col-md-8 send-CV">
            Send your CV and your video link to{' '}
            <a href="mailto:hr@hexact.io" target='_blank' rel='noopener'>hr@hexact.io</a>
          </div>
        </>
      ) : (
        <>
          <div className="col-12 col-sm-6 deadline">
            Application Deadline: {vacancy.deadline}
          </div>
          <div className="col-12 col-sm-6 send-CV">
            Send your CV to <a href="mailto:hr@hexact.io" target='_blank' rel='noopener'>hr@hexact.io</a>
          </div>
        </>
      )}
    </div>
  </div>
);

export default JobDescription;
