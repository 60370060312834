import React, {FC} from 'react';
import {Link} from 'gatsby';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faLongArrowAltLeft} from '@fortawesome/free-solid-svg-icons';
import {BackButtonProps} from '../../types';
import './styles.scss';

const BackButton: FC<BackButtonProps> = ({path, text}) => (
  <div className="col-12">
    <Link to={path} className="learn_btn_two">
      <FontAwesomeIcon icon={faLongArrowAltLeft} className="icon" />
      {text}
    </Link>
  </div>
);

export default BackButton;
